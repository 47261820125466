import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'shared/breakpoints';
import { navigate } from 'gatsby';
import { ROUTE_CONTACT } from 'shared/paths';
import ArrowSvg from 'assets/images/_infoGraphics/arrow.svg';

export const PersonalizedSection = () => {
  return (
    <Wrapper>
      <HeaderText>Let us create a personalized GPT just for you</HeaderText>
      <Text>
        Ready to see what AI can do for your business? Let’s design a future-forward solution that works for you.
      </Text>
      <Button onClick={() => navigate(ROUTE_CONTACT)}>
        contact sales
        <img src={ArrowSvg} alt="exploreButton" />
      </Button>
    </Wrapper>
  );
};

const HeaderText = styled.div`
  color: ${({ theme: { colors } }) => colors.white};
  font-family: ${({ theme: { fontFamily } }) => fontFamily.heading};
  max-width: 720px;
  font-size: 50px;
  font-weight: 300;
  line-height: 1.5;

  ${mediaQueries.md} {
    font-size: 36px;
  }
`;

const Text = styled.div`
  color: ${({ theme: { colors } }) => colors.white};
  font-size: 14px;
  font-weight: 300;
  line-height: 1.79;
  margin: 36px 0 28px 0;
  max-width: 445px;
`;

const Wrapper = styled.div`
  background: #303030;
  display: flex;
  flex-direction: column;
  padding: 118px 156px 92px 156px;
  width: 100%;

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Button = styled.button`
  color: ${({ theme: { colors } }) => colors.white};
  width: 255px;
  height: 45px;
  padding: 13px 17px 13px 28px;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.light};
  outline: none;
  cursor: pointer;
  transition: 120ms ease opacity;

  &:hover {
    opacity: 0.8;
  }
`;
