import React from 'react';
import styled from 'styled-components';
import iconData from 'assets/images/_icons/iconData.svg';
import iconDataFlow from 'assets/images/_icons/iconDataFlow.svg';
import iconChat from 'assets/images/_icons/iconChat.svg';
import iconLayout from 'assets/images/_icons/iconLayout.svg';
import iconChart from 'assets/images/_icons/iconChartBreakout.svg';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.section};
  display: flex;
  flex-direction: column;
  padding: 156px;
  width: 100%;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 64px;
  background: ${({ theme: { colors } }) => colors.background};

  ${mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 36px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 300;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: 300;
  padding: 40px 0;

  ${mediaQueries.sm} {
    padding: 0 0 40px 0;
  }

  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    color: #f6f9ff;
    margin-top: 12px;
    display: block;
    max-width: 720px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 64px;
  justify-content: center;
  align-items: center;
`;

export const SolutionsSection = () => {
  return (
    <Wrapper>
      <Title>Obtain a personalized GPT solution for your business</Title>
      <Content>
        {data.map(({ icon, title, sub }, idx) => (
          <Item key={idx}>
            <IconWrapper>
              <Common.Svg h="48px" w="48px" src={icon} />
            </IconWrapper>
            <Text>
              {title}
              <span>{sub}</span>
            </Text>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    icon: iconChart,
    title: 'Custom GPT development',
    sub: 'build GPT models from scratch, tailored to your business needs, automating workflows and enhancing customer interactions.',
  },
  {
    icon: iconData,
    title: 'Fine-tuned GPT',
    sub: 'we train GPT on your data to create AI that understands your business and delivers precise, high-quality results.',
  },
  {
    icon: iconLayout,
    title: 'AIConsole implementation',
    sub: 'manage and control your GPT solutions in real-time with an intuitive AI console, offering full oversight and easy adjustments.',
  },
  {
    icon: iconChat,
    title: 'GPT consulting and maintenance',
    sub: 'our experts guide you through the strategic implementation of GPT, from initial concept to full deployment.',
  },
  {
    icon: iconDataFlow,
    title: 'Whitelabel AI solutions',
    sub: 'branded AI solutions with our whitelabel GPT services, while we manage the backend development and support.',
  },
];
