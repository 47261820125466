import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'shared/breakpoints';

export const DiverseSection = () => {
  return (
    <Wrapper>
      <HeaderText>Diverse GPT applications for business growth</HeaderText>
      <Text>
        Our GPT solutions are versatile and adaptable, allowing businesses in any industry to harness AI for specific
        tasks. Explore how GPT can support your business's growth:
      </Text>

      <Content>
        {data.map(({ title, sub }, idx) => (
          <Item key={idx}>
            {title}
            <span>{sub}</span>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    title: 'Smart HR screening',
    sub: 'automate resume sorting and candidate assessments to fast-track top talent.',
  },
  {
    title: 'Swift onboarding system',
    sub: 'streamline employee onboarding with instant answers and automated tasks.',
  },
  {
    title: 'Rapid customer response',
    sub: 'deliver quick, accurate customer replies via AI-driven chatbots and emails.',
  },
  {
    title: 'Sales lead optimization',
    sub: 'prioritize and nurture leads with AI insights that drive faster deals.',
  },
  {
    title: 'Marketing automation',
    sub: 'automate personalized campaigns with AI-powered content and targeting.',
  },
  {
    title: 'Efficient legal research',
    sub: 'cut legal research time-AI scans documents and provides precise insights.',
  },
  {
    title: 'In-depth financial analysis',
    sub: 'get predictive financial insights with AI-driven analysis and reports.',
  },
  {
    title: 'Document management',
    sub: 'automate document categorization and retrieval for instant access anytime.',
  },
];

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.background};
  display: flex;
  flex-direction: column;
  padding: 118px 156px 92px 156px;
  width: 100%;

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Content = styled.div`
  margin-top: 100px;
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  justify-items: center;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
    justify-items: start;
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: start;
  }
`;

const HeaderText = styled.div`
  color: ${({ theme: { colors } }) => colors.white};
  font-family: ${({ theme: { fontFamily } }) => fontFamily.heading};
  font-size: 36px;
  font-weight: 300;
  line-height: 1.53;
`;

const Text = styled.div`
  margin-top: 50px;
  color: ${({ theme: { colors } }) => colors.white};
  font-size: 16px;
  font-weight: 300;
  line-height: 2.19;
  max-width: 720px;
`;

const Item = styled.div`
  padding: 50px 32px;
  background-color: ${({ theme: { colors } }) => colors.section};
  font-size: 20px;
  font-weight: 500;

  span {
    font-size: 14px;
    font-weight: 300;
    display: block;
    line-height: 1.79;
    margin-top: 48px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
